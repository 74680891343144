<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
  </v-app>
</template>

<script>
import axios from "axios";
import store from "./store";
import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";
export default {
  name: "App",
  components: {},
  data() {
    return {
      showsnackbar: false,
      showsnackbar1:false,
      msg: null,
      timeout: 20000,
    };
  },
  beforeMount() {
    if (typeof localStorage.getItem("token") == "string") {
      axios({
        method: "GET",
        url: "/superAdmin/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            store.commit("userData", response.data.data);
            store.commit("userType", response.data.data.type);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {},
};
</script>

<style>
.v-rating .v-icon {
  padding: 0px !important;
}
</style>
