import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import store from '@/store'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [{
            path: "/Login",
            name: "Login",
            component: () =>
                import ('./components/Auth/login'),
            meta: { requiresAuth: false }

        },
        {
            path: "*",
            name: "404pagenotfound",
            props: true,
            component: () =>
                import ("./components/Common/404"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/servererror",
            name: "servererror",
            props: true,
            component: () =>
                import ("./components/Common/500"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: '/',
            redirect: '/dashboard',
            component: () =>
                import ('@/layouts/Layout'),
            meta: { requiresAuth: true },
            children: [
                // Components

                {
                    name: 'Dashboard',
                    path: 'dashboard',
                    component: () =>
                        import ('./components/Views/Dashboard/basicDashboard'),
                },
                {
                    path: "/homeSlider",
                    name: "HomeSlider",
                    component: () =>
                        import ("./components/Views/Home/homeSlider"),
                },
                {
                    path: "/story",
                    name: "story",
                    component: () =>
                        import ("./components/Views/OurStory/story"),
                },
                {
                    path: "/goal",
                    name: "goal",
                    component: () =>
                        import ("./components/Views/Goals/goal"),
                },
                {
                    path: "/middleBanner",
                    name: "middleBanner",
                    component: () =>
                        import ("./components/Views/MiddleBanner/middleBanner"),
                },
                {
                    path: "/sellerAdd",
                    name: "sellerAdd",
                    component: () =>
                        import ("./components/Views/Seller/sellerAdd"),
                },
                {
                    path: "/sellerList",
                    name: "sellerList",
                    component: () =>
                        import ("./components/Views/Seller/sellerList"),
                },
                {
                    path: "/sellerEdit",
                    name: "sellerEdit",
                    component: () =>
                        import ("./components/Views/Seller/sellerEdit"),
                },
                {
                    path: "/sellerView",
                    name: "sellerView",
                    component: () =>
                        import ("./components/Views/Seller/sellerView"),
                },
                {
                    path: "/category",
                    name: "category",
                    props: true,
                    component: () =>
                        import ("./components/Views/Category/category"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/blog",
                    name: "blog",
                    props: true,
                    component: () =>
                        import ("./components/Views/Blog/blog"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/about",
                    name: "about",
                    props: true,
                    component: () =>
                        import ("./components/Views/About/about"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/userList",
                    name: "userList",
                    props: true,
                    component: () =>
                        import ("./components/Views/User/userList"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/userDetails",
                    name: "userDetails",
                    props: true,
                    component: () =>
                        import ("./components/Views/User/userDetails"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/products",
                    name: "products",
                    props: true,
                    component: () =>
                        import ("./components/Views/Product/products"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/productEdit",
                    name: "productEdit",
                    props: true,
                    component: () =>
                        import ("./components/Views/Product/productEdit"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/purchaseList",
                    name: "purchaseList",
                    props: true,
                    component: () =>
                        import ("./components/Views/Purchase/purchaseList"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/purchaseDetails",
                    name: "purchaseDetails",
                    props: true,
                    component: () =>
                        import ("./components/Views/Purchase/purchaseDetails"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/paymentReport",
                    name: "paymentReport",
                    props: true,
                    component: () =>
                        import ("./components/Views/Purchase/paymentReport"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/orderReportSeller",
                    name: "orderReportSeller",
                    props: true,
                    component: () =>
                        import ("./components/Views/Purchase/orderReportSeller"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/orderReport",
                    name: "orderReport",
                    props: true,
                    component: () =>
                        import ("./components/Views/Reports/orderReport"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/privacyPolicy",
                    name: "privacyPolicy",
                    props: true,
                    component: () =>
                        import ("./components/Views/Settings/privacyPolicy"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/cancellationPolicy",
                    name: "cancellationPolicy",
                    props: true,
                    component: () =>
                        import ("./components/Views/Settings/cancellationPolicy"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/termsAndConditions",
                    name: "termsAndConditions",
                    props: true,
                    component: () =>
                        import ("./components/Views/Settings/termsAndConditions"),
                    meta: {
                        requiresAuth: false,
                    },
                },
            ]
        },

    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
})

router.beforeEach((to, from, next) => {
    if (localStorage.getItem('token')) {
        var newURL = ''
        newURL = "/validate/token";
        axios({
                method: "POST",
                url: newURL,
                headers: {
                    "token": localStorage.getItem("token")
                }
            })
            .then(response => {
                if (!response.data.status) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("role");
                    store.commit("logoutUser");
                    store.commit("sessionOut", true)
                    next({
                        name: 'Login'
                    })
                    return
                }
            })
    }

    if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
        next({
            name: 'Login'
        })
        return
    }
    if (to.path === '/Login' && store.state.isLoggedIn) {
        next({
            path: '/'
        })
        return
    }
    next()
})

export default router